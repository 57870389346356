import { createAsyncThunk } from '@reduxjs/toolkit';

import api from 'api';
import { WEB_API_ROUTES } from 'api/api-routes';
import { handleServerErrors } from 'utils/server-errors';

const authSendVerification = createAsyncThunk(
  'auth/sendVerification',
  async (login) => {
    try {
      const response = await api.post(WEB_API_ROUTES.auth.sendVerification, {
        login,
      });

      return response.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const login = createAsyncThunk('auth/login', async ({ credentials, code }) => {
  try {
    const body = {
      code,
      method: 'code',
    };

    if (credentials.type === 'email') {
      body.email = credentials.value;
    }

    if (credentials.type === 'phone') {
      body.phone = credentials.value;
    }

    const response = await api.post(WEB_API_ROUTES.auth.login, body);

    return response.data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const refreshToken = createAsyncThunk(
  'auth/refreshToken',
  async (_, { getState }) => {
    try {
      const { auth } = getState();
      const response = await api.post(WEB_API_ROUTES.auth.refreshToken, {
        refresh_token: auth.refreshToken,
      });

      return response.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const sendLogout = createAsyncThunk('auth/logout', async () => {
  try {
    const response = await api.post(WEB_API_ROUTES.profile.logout);

    return response.data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const thunks = {
  authSendVerification,
  login,
  refreshToken,
  sendLogout,
};

export { thunks };
