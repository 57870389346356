import { createAsyncThunk } from '@reduxjs/toolkit';

import api from 'api';
import { WEB_API_ROUTES } from 'api/api-routes';
import { handleServerErrors } from 'utils/server-errors';
import { apiWrapper } from '../../api/apiWrapper';

const getProfile = createAsyncThunk('profile/getProfile', async () => {
  try {
    const response = await apiWrapper.get(WEB_API_ROUTES.profile.info);

    return response.data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const changeProfileFirstLogin = createAsyncThunk(
  'profile/changeProfileFirstLogin',
  async () => {
    try {
      const response = await api.post(WEB_API_ROUTES.profile.changeFirstLogin);

      return response.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getEvaluation = createAsyncThunk(
  'profile/getEvaluation',
  async (dancerId) => {
    try {
      const response = await api.get(
        WEB_API_ROUTES.profile.evaluation.replace('{dancerId}', dancerId)
      );

      return response.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getRating = createAsyncThunk('profile/getRating', async (dancerId) => {
  try {
    const url = WEB_API_ROUTES.profile.rating.replace('{dancerId}', dancerId);
    const response = await api.get(url);

    return response.data;
  } catch (err) {
    handleServerErrors(err);
  }
});

const getDancersList = createAsyncThunk('profile/getDancersList', async () => {
  try {
    const response = await apiWrapper.get(WEB_API_ROUTES.profile.dancers);

    return response.data;
  } catch (err) {
    handleServerErrors(err);
  }
});

const thunks = {
  getProfile,
  changeProfileFirstLogin,
  getEvaluation,
  getRating,
  getDancersList,
};

export { thunks };
