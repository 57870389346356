import { createSlice } from '@reduxjs/toolkit';
import { thunks } from './thunks';
import { selectors } from './selectors';

const initialState = {
  isFirstLogin: false,
  profile: null,
  selectedDancer: null,
  evaluation: null,
  rating: null,
  dancersList: [],
};

const slice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setSelectedDancer(state, action) {
      state.selectedDancer = action.payload;
    },
    resetProfileState() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getProfile.fulfilled, (state, action) => {
        const { is_first_login } = action.payload.data;

        state.isFirstLogin = is_first_login;
        state.profile = action.payload?.data;
      })
      .addCase(thunks.changeProfileFirstLogin.fulfilled, (state, action) => {
        const { is_first_login } = action.payload.data;

        state.isFirstLogin = is_first_login;
      })
      .addCase(thunks.getEvaluation.fulfilled, (state, action) => {
        state.evaluation = action.payload?.data;
      })
      .addCase(thunks.getRating.fulfilled, (state, action) => {
        state.rating = action.payload?.data;
      })
      .addCase(thunks.getDancersList.fulfilled, (state, action) => {
        state.dancersList = action.payload?.data;
      });
  },
});

const profile = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { profile };
export default slice.reducer;
