import { createAsyncThunk } from '@reduxjs/toolkit';

import { WEB_API_ROUTES } from 'api/api-routes';
import api from 'api';
import { handleServerErrors } from 'utils/server-errors';

const getClientSecret = createAsyncThunk(
  'payment/getClientSecret',
  async ({ payment_method, email, amount }) => {
    try {
      const response = await api.post(WEB_API_ROUTES.payment.payment, {
        lesson_id: 1,
        payment_method,
        email,
        amount,
      });

      return response.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const addPaymentMethod = createAsyncThunk(
  'payment/addPaymentMethod',
  async (paymentId) => {
    try {
      const response = await api.post(WEB_API_ROUTES.payment.addPaymentMethod, {
        id: paymentId,
      });

      return response.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const pay = createAsyncThunk(
  'payment/pay',
  async ({ paymentMethodId, lessonId }) => {
    try {
      const response = await api.post(WEB_API_ROUTES.payment.pay, {
        payment_method_id: paymentMethodId,
        lesson_id: lessonId,
      });

      return response.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getTransactionsList = createAsyncThunk(
  'payment/getTransactionsList',
  async () => {
    try {
      const response = await api.get(WEB_API_ROUTES.payment.transactionsList);
      return response.data;
    } catch (err) {
      handleServerErrors(err);
    }
  }
);

const thunks = {
  getClientSecret,
  addPaymentMethod,
  pay,
  getTransactionsList,
};

export { thunks };
