const BASE_URLS = {
  auth: '/auth',
  signIn: '/sign-in',
  schedules: '/schedules',
  profile: '/profile',
  classes: '/classes',
  routines: '/routines',
  payment: '/payment/stripe',
  media: '/media',
  dancers: '/dancers',
};

export const WEB_API_ROUTES = {
  auth: {
    sendVerification: `${BASE_URLS.signIn}/send-verification`,
    login: `${BASE_URLS.signIn}/login`,
    refreshToken: `${BASE_URLS.auth}/refresh`,
  },

  schedule: {
    schedules: BASE_URLS.schedules,
    private: `${BASE_URLS.schedules}/private-lesson`,
    requestLesson: `${BASE_URLS.schedules}/create-private-lesson-requests`,
    pendingRequests: `${BASE_URLS.schedules}/private-lesson-requests`,
  },

  profile: {
    info: BASE_URLS.profile,
    evaluation: `${BASE_URLS.dancers}/{dancerId}/evaluation`,
    rating: `${BASE_URLS.dancers}/{dancerId}/ratings`,
    dancers: BASE_URLS.dancers,
    logout: `${BASE_URLS.profile}/logout`,
    changeFirstLogin: `${BASE_URLS.profile}/change-first-login`,
  },

  class: {
    list: BASE_URLS.classes,
    classroom: `${BASE_URLS.classes}/{classId}`,
    schedules: '/class/schedules',
  },

  routine: {
    routineList: BASE_URLS.routines,
    currentRoutine: `${BASE_URLS.routines}/{routineId}`,
  },

  payment: {
    payment: `${BASE_URLS.payment}/create-private-intent`,
    addPaymentMethod: `${BASE_URLS.payment}/add-payment-method`,
    pay: `${BASE_URLS.payment}/pay`,
    transactionsList: `${BASE_URLS.payment}/transactions`,
    checkout: `${BASE_URLS.payment}/checkout`,
  },

  media: {
    classroom: `${BASE_URLS.media}/classroom`,
    routines: `${BASE_URLS.media}/routine`,
    mediaMain: `${BASE_URLS.media}/student`,
    mediaById: `${BASE_URLS.media}/get`,
  },
};
