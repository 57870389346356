import axios from 'axios';
import { APP_ROUTES } from 'router/appRoutes';

import store from 'store';
import { auth } from 'store/auth';
import { profile } from 'store/profile';
import { showToast } from 'utils/showToast';
import { core } from '../store/core';

let isUnauthorizedBlocked = false;

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api`,
  timeout: 30000,
});

const blockUnauthorized = () => {
  isUnauthorizedBlocked = true;

  setTimeout(() => {
    isUnauthorizedBlocked = false;
  }, 10000);
};

instance.interceptors.request.use((config) => {
  const { auth } = store.getState();

  if (auth.token) config.headers.Authorization = `Bearer ${auth.token}`;
  return config;
});

// logout interceptor
instance.interceptors.response.use(
  (res) => res,
  (err) => {
    const { status } = err.response;

    if (status === 401) {
      if (!isUnauthorizedBlocked) {
        blockUnauthorized();

        store.dispatch(auth.actions.setUnauthorized());
        store.dispatch(profile.actions.resetProfileState());
        store.dispatch(core.actions.setLoading(false));
        showToast.error('Unauthorized');
      }
    }

    if (status === 403) {
      window.location.href = APP_ROUTES.notFound;
    }

    return Promise.reject(err);
  }
);

export default instance;
