import storage from 'redux-persist/lib/storage';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';

// REDUCERS
import auth from 'store/auth';
import profile from 'store/profile';
import schedule from 'store/schedule';
import classes from 'store/classes';
import routines from 'store/routines';
import payment from 'store/payment';
import media from 'store/media';
import core from 'store/core';

const rootReducer = combineReducers({
  core,
  auth,
  profile,
  schedule,
  classes,
  routines,
  payment,
  media,
});

const persistConfig = {
  key: 'parentPortal',
  storage,
  blacklist: [],
  whitelist: ['auth', 'schedule', 'profile'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export default store;
