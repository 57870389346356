import { createAsyncThunk } from '@reduxjs/toolkit';

import { WEB_API_ROUTES } from 'api/api-routes';
import { handleServerErrors } from 'utils/server-errors';
import { apiWrapper } from '../../api/apiWrapper';

const getClasses = createAsyncThunk(
  'students/getClasses',
  async (studentId) => {
    try {
      const response = await apiWrapper.get(WEB_API_ROUTES.class.list, {
        params: {
          student_id: studentId,
        },
      });

      return response.data;
    } catch (err) {
      handleServerErrors(err);
    }
  }
);

const getClassroom = createAsyncThunk(
  'students/getClassroom',
  async (classId) => {
    try {
      const response = await apiWrapper.get(
        WEB_API_ROUTES.class.classroom.replace('{classId}', classId)
      );

      return response.data;
    } catch (err) {
      handleServerErrors(err);
    }
  }
);

const thunks = {
  getClasses,
  getClassroom,
};

export { thunks };
