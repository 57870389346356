import { createSlice } from '@reduxjs/toolkit';

import { status } from 'utils/const';
import { thunks } from './thunks';
import { selectors } from './selectors';

const initialState = {
  token: null,
  refreshToken: null,
  roles: [],
  loginStatus: status.IDLE,
  logoutStatus: status.IDLE,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken(state, action) {
      state.token = action.payload;
    },
    setUnauthorized() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.login.pending, (state) => {
        state.loginStatus = status.PENDING;
      })
      .addCase(thunks.login.fulfilled, (state, action) => {
        state.loginStatus = status.SUCCESS;

        const { accessToken, refreshToken } = action.payload?.data;
        state.token = accessToken;
        state.refreshToken = refreshToken;
      })
      .addCase(thunks.login.rejected, (state) => {
        state.loginStatus = status.FAIL;
      })
      .addCase(thunks.refreshToken.fulfilled, (state, action) => {
        state.token = action.payload?.data?.accessToken;
        state.refreshToken = action.payload?.data?.accessToken;
      })
      .addCase(thunks.sendLogout.fulfilled, (state) => {
        Object.assign(state, initialState);
      });
  },
});

const auth = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { auth };
export default slice.reducer;
