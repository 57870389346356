import { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { APP_ROUTES } from 'router/appRoutes';
import FooterNavigation from 'components/FooterNavigation';
import { NotFoundPage } from 'pages/NotFound';
import { useScrollToTop } from 'hooks/useScrollToTop';
import { RLoadingOverlay } from 'components/RLoadingOverlay';
import { core } from 'store/core';

const AuthLayout = lazy(() => import('layouts/AuthLayout'));
const UserLayout = lazy(() => import('layouts/UserLayout'));

function App() {
  const showLoading = useSelector(core.selectors.showLoading);
  useScrollToTop();

  return (
    <Suspense>
      <RLoadingOverlay isVisible={showLoading} />

      <Switch>
        <Route path={APP_ROUTES.auth} component={AuthLayout} />
        <Route path={APP_ROUTES.index} component={UserLayout} />
        <Route path="*" component={NotFoundPage} />
      </Switch>

      <FooterNavigation />
    </Suspense>
  );
}

export default App;
