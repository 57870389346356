import { createSlice } from '@reduxjs/toolkit';

import { thunks } from './thunks';
import { selectors } from './selectors';
import { status } from 'utils/const';

const initialState = {
  classes: [],
  currentClass: null,

  currentClassLoadStatus: status.IDLE,
};

const slice = createSlice({
  name: 'classes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getClasses.fulfilled, (state, action) => {
        state.classes = action.payload?.data;
      })

      .addCase(thunks.getClassroom.pending, (state) => {
        state.currentClassLoadStatus = status.PENDING;
      })
      .addCase(thunks.getClassroom.fulfilled, (state, action) => {
        state.currentClass = action.payload?.data;
        state.currentClassLoadStatus = status.SUCCESS;
      })
      .addCase(thunks.getClassroom.rejected, (state) => {
        state.currentClassLoadStatus = status.FAIL;
      });
  },
});

const classes = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { classes };
export default slice.reducer;
