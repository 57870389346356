import { useCallback } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';

import { RPopupPrompt } from 'components/RPopupPrompt';

// eslint-disable-next-line react/prop-types
function Router({ children }) {
  const userConfirmation = useCallback((message, callback) => {
    const node = document.getElementById('modal-portal');

    const cleanUp = (answer) => {
      root.unmount();
      callback(answer);
    };

    const root = createRoot(node);
    root.render(<RPopupPrompt message={message} cleanUp={cleanUp} />);
  }, []);

  return (
    <BrowserRouter getUserConfirmation={userConfirmation}>
      {children}
    </BrowserRouter>
  );
}

export default Router;
