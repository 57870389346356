import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { NOT_FOUND_404_SVG } from 'components/ui/_shared/SVG';
import { Typography } from 'components/App/GlobalStyled';
import { colors } from 'utils/colors';
import { RButton } from 'components/ui/RButton';
import { APP_ROUTES } from 'router/appRoutes';

const NotFoundPageStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50%;
  height: 100%;
  padding: 0 16px;
`;

export const NotFoundPage = () => {
  const history = useHistory();

  const onReturnHome = () => {
    history.push(APP_ROUTES.schedule.scheduleMain);
  };

  return (
    <NotFoundPageStyled>
      <NOT_FOUND_404_SVG />

      <Typography fz="20px" fw="600" m="24px 0 16px" color={colors.brand700}>
        404 Error: Page Not Found
      </Typography>

      <Typography
        fz="16px"
        fw="400"
        p="0 16px"
        ta="center"
        m="0 0 54px 0"
        color={colors.brand700}
      >
        Oops! Looks like you&apos;ve hit a dead end. Apologies for the
        inconvenience. Let&apos;s get you back on track!
      </Typography>

      <RButton height={70} fullWidth onClick={onReturnHome}>
        Return to home
      </RButton>
    </NotFoundPageStyled>
  );
};
