import api from 'api';

export const apiWrapper = {
  get: async (url, config = {}) => {
    const updatedConfig = {
      ...config,
      params: {
        ...(config.params || {}),
        app: 'parent',
      },
    };

    return api.get(url, updatedConfig);
  },
};
