import { createAsyncThunk } from '@reduxjs/toolkit';

import api from 'api';
import { WEB_API_ROUTES } from 'api/api-routes';
import { handleServerErrors } from 'utils/server-errors';

const getClassMedia = createAsyncThunk(
  'media/getClassMedia',
  async (classroomId) => {
    try {
      const response = await api.get(WEB_API_ROUTES.media.classroom, {
        params: {
          id: classroomId,
        },
      });

      return response.data;
    } catch (err) {
      handleServerErrors(err);
    }
  }
);

const getRoutinesMedia = createAsyncThunk(
  'media/getRoutinesMedia',
  async (classroomId) => {
    try {
      const response = await api.get(WEB_API_ROUTES.media.routines, {
        params: {
          id: classroomId,
        },
      });

      return response.data;
    } catch (err) {
      handleServerErrors(err);
    }
  }
);

const getMedia = createAsyncThunk('media/getMedia', async (dancerId) => {
  try {
    const response = await api.get(WEB_API_ROUTES.media.mediaMain, {
      params: {
        id: dancerId,
      },
    });

    return response.data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const getMediaById = createAsyncThunk('media/getMediaById', async (mediaId) => {
  try {
    const response = await api.get(WEB_API_ROUTES.media.mediaById, {
      params: {
        id: mediaId,
      },
    });

    return response.data;
  } catch (err) {
    handleServerErrors(err);
  }
});

const thunks = {
  getClassMedia,
  getRoutinesMedia,
  getMedia,
  getMediaById,
};

export { thunks };
