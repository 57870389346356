import { createGlobalStyle, css } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
    ${({ theme }) => css`
      @font-face {
        font-family: 'Inter';
        font-weight: 300;
        font-style: normal;
      }

      * {
        &::-webkit-scrollbar {
          height: 4px;
          width: 4px;
        }

        &::-webkit-scrollbar-track {
          background: ${theme.white};
          border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb {
          background: ${theme.osloGrey};
          border-radius: 4px;
        }
      }

      body {
        max-width: 420px;
        margin: 0 auto;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 145%;
        color: rgb(3, 24, 42);
        background-color: ${theme.gray50};

        .animate {
          opacity: 0;
        }

        .no-inner-scroll {
          overflow: hidden;
        }

        .Toastify__toast-container {
          top: 90px;
          right: 16px;
          padding: 0 16px;
          word-break: break-word;

          .customIcon {
            background-image: url('react/src/assets/img/SVG.js');
            width: 20px;
            height: 20px;
          }

          .Toastify__toast {
            border-radius: 12px;
            padding: 0;
            min-height: auto;
            font-size: 16px;
            font-weight: 500;
            line-height: normal;
            font-family: 'Inter', sans-serif;
            color: ${theme.gray950};
            border: 0.5px solid ${theme.green500};
            background-color: ${theme.green25};

            &--error {
              border: 0.5px solid ${theme.red500};
              background-color: ${theme.red100};
            }

            &-body {
              padding: 14px 18px;
              margin: 0;
            }

            &-icon {
              width: 24px;
            }
          }
        }
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-family: 'Roboto', sans-serif;
      }

      h1 {
        font-size: 42px;
        font-weight: 500;
        line-height: 130%;
        letter-spacing: -0.1px;
      }

      h2 {
        font-size: 24px;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: 0;
      }

      h3 {
        font-size: 22px;
        font-weight: 400;
        line-height: 140%;
        letter-spacing: 0.5px;
      }

      h4 {
        font-size: 20px;
        font-weight: 400;
        line-height: 140%;
        letter-spacing: 0.5px;
      }

      h5 {
        font-size: 16px;
        font-weight: 400;
        line-height: 140%;
        letter-spacing: 0.3px;
      }
    `}
`;
