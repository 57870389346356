import PropTypes from 'prop-types';

import { RLoader } from 'components/RLoader';
import { LineLoader } from './LineLoader';
import { RMask } from 'components/RMask';

RLoadingOverlay.propTypes = {
  isVisible: PropTypes.bool,
  children: PropTypes.any,
  color: PropTypes.string,
};

export function RLoadingOverlay({
  isVisible,
  color = 'rgba(67, 65, 73, 0.45)',
  children,
}) {
  return (
    <RMask isVisible={isVisible} color={color}>
      <LineLoader />
      {children || <RLoader />}
    </RMask>
  );
}
